import { Injectable } from '@angular/core';
import {
  CollectionReference,
  DocumentReference,
  Firestore,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  or,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import {
  NetFirebaseTeamMember,
  NetTeamSeatplan,
  NetTeamSeatplanFirebase,
  NetUserFirebase,
  NetUserPermissionFirebase,
} from '@net-types';
import { first } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(private angularFirestore: Firestore) {}

  public async getSeatplan$(): Promise<NetTeamSeatplan | undefined> {
    const seatplanDocument = doc(
      this.angularFirestore,
      `/seatplan/seatplan`
    ) as DocumentReference<NetTeamSeatplanFirebase>;

    const docSnap = await getDoc(seatplanDocument);

    if (docSnap.exists()) {
      return docSnap.data().seatplan;
    } else {
      return undefined;
    }
  }

  public async setSeatplan(seatplan: NetTeamSeatplan): Promise<void> {
    return await setDoc(doc(this.angularFirestore, `/seatplan/seatplan`), {
      seatplan: seatplan,
    });
  }

  public async addUser(id: string, email: string): Promise<void> {
    const user: NetUserFirebase = { email: email };

    await setDoc(doc(this.angularFirestore, `/users/${id}`), user);
  }

  public async getUserPermissions$(
    userId: string
  ): Promise<NetUserPermissionFirebase | undefined> {
    const permissionsDoc = doc(
      this.angularFirestore,
      `permissions`,
      userId
    ) as DocumentReference<NetUserPermissionFirebase>;
    const docSnap = await getDoc(permissionsDoc);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return undefined;
    }
  }

  public async saveTeamMember(
    email: string,
    teamMember: NetFirebaseTeamMember
  ): Promise<void> {
    await addDoc(collection(this.angularFirestore, `team`), teamMember);
  }

  public async getTeamMember$(
    email: string,
    abbreviation: string
  ): Promise<NetFirebaseTeamMember | null> {
    const interviewCollection = collection(
      this.angularFirestore,
      `team`
    ) as CollectionReference<NetFirebaseTeamMember>;
    const snapshot = await getDocs(
      query(
        interviewCollection,
        or(
          where('identifier', '==', email),
          where('identifier', '==', abbreviation)
        ),
        limit(1)
      )
    );

    if (snapshot.empty) {
      return null;
    } else {
      return first(snapshot.docs)?.data() || null;
    }
  }
}
